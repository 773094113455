/* customDatePickerStyles.css */
.customDatePickerContainer {
  width: 130px; /* Adjust the width as needed */
  border: none;
}

.customDatePickerContainer > div.react-datepicker-wrapper,
.customDatePickerContainer > div > div.react-datepicker__input-container,
.customDatePickerContainer > div > div.react-datepicker__input-container input {
  width: 100%;
  border: none; /* Remove border from all elements */
  box-shadow: none; /* Remove any box shadow if present */
  outline: none; /* Remove outline when focused */
  font-size: 16px; /* Increase font size */
}

.customDatePickerContainer > div > div.react-datepicker__input-container input {
  height: 30px; /* Example height */
}

.react-datepicker__header {
  background-color: #ECF6FF; /* Header background color */
  border-bottom: none; /* Remove bottom border */
}

.react-datepicker__day,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  font-size: 12px; /* Font size for days */
}

.react-datepicker__month-container {
  border: none; /* Remove border around month container */
}

.react-datepicker__month {
  border: none; /* Remove border around month */
}

.react-datepicker__triangle {
  display: none; /* Hide triangle */
}

/* Scrollbar styles */
.react-datepicker__month-container::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.react-datepicker__month-container::-webkit-scrollbar-track {
  background: transparent; /* Track background color */
  border-radius: 4px; /* Rounded corners for the track */
}

.react-datepicker__month-container::-webkit-scrollbar-thumb {
  background: #9C9C9C; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

/* Custom styles for the time picker dropdown */
.react-datepicker__time-container .react-datepicker__time-box {
  width: 100px; /* Adjust the width of the time picker */
}

.react-datepicker__time-container .react-datepicker__time-box ul.react-datepicker__time-list {
  padding: 0; /* Remove padding from the time list */
  margin: 0; /* Remove margin from the time list */
}

/* Time dropdown styles */
.react-datepicker__time-container {
  /* background-color: #ECF6FF; Background color for time dropdown */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: transparent;
  border: none;
}

.react-datepicker__time-container .react-datepicker__time-list {
  padding: 0;
  background-color: transparent;
}

.react-datepicker__time-container .react-datepicker__time-list-item {
  font-size: 14px; /* Adjust font size */
  padding: 10px; /* Adjust padding */
  border-radius: 4px; /* Rounded corners for time items */
}

/* Ensure high specificity to override other styles */
.customDatePickerContainer .react-datepicker__time-container .react-datepicker__time-list-item:hover {
  background-color: #007BFF !important; /* Background color for hovered time */
  color: white !important; /* Text color for hovered time */
}
.customDatePickerContainer .react-datepicker__time-container .react-datepicker__time-list-item--selected {
  background-color: #0056b3 !important; /* Background color for selected time */
  color: white !important; /* Text color for selected time */
}
.react-datepicker__time-container .react-datepicker__time-list-item {
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Custom scrollbar for time dropdown */
.react-datepicker__time-container .react-datepicker__time-list::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  border-radius: 4px;
}

.react-datepicker__time-container .react-datepicker__time-list::-webkit-scrollbar-track {
  background: transparent; /* Track background color */
  border-radius: 4px; /* Rounded corners for the track */
}

.react-datepicker__time-container .react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #9C9C9C; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners for the thumb */
}
