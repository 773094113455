@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body,
* {
    font-family: "Montserrat", sans-serif !important;
}

body {
    padding: 0; 
    margin: 0;
}

.custom-drawer .MuiDrawer-paper {
    background: #100345;
}

.textarea-placeholder::-webkit-input-placeholder {
    color: #2A589C;
    opacity: 0.5;
}

.textarea-placeholder:-ms-input-placeholder {
    color: #2A589C;
    opacity: 0.5;
}

.textarea-placeholder::-moz-placeholder {
    color: #2A589C;
    opacity: 0.5;
}

.textarea-placeholder :-moz-placeholder {
    color: #2A589C;
    opacity: 0.5;
}

.ud-link:active {
   color: #073985;
}

@media (max-width: 1280px) and (min-width: 1200px) {
    html,
    body {
        font-size: 12px;
    }
}
@media (max-width: 1600px) {
    html,
    body {
        font-size: 14px;
    }
}